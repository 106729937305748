import React, { FC, memo, ReactNode, useEffect, useRef } from 'react';

interface Props {
	children: ReactNode;
	className: string;
	flip: boolean;
}

const Paper: FC = memo(({ children, className, flip }: Props) => {
	const paperEl = useRef<HTMLDivElement>(null);

	const setCalculatedHeight = () => {
		if (paperEl.current) {
			const container = paperEl.current.querySelector('.b-paper__container');
			const side = paperEl.current.querySelector(`.b-paper__${flip ? 'back' : 'front'} > .b-paper__container`);
			if (container && side) {
				container.style.height = `${side.clientHeight}px`;
			}
		}
	};

	useEffect(() => {
		setCalculatedHeight();
		const observer = new MutationObserver(setCalculatedHeight);
		observer.observe(paperEl.current, {
			attributes: true,
			childList: true,
			subtree: true,
		});
		return () => observer.disconnect();
	}, [flip]);

	return (
		<section>
			<div className="row-main">
				<div
					ref={paperEl}
					className={`b-paper${flip ? ` b-paper--flip` : ''}${className ? ` ${className}` : ''}`}
				>
					<div className="b-paper__container">{children}</div>
				</div>
			</div>
		</section>
	);
});

export default Paper;
